/**
 * SystemsInsertModal.jsx
 * ----------------------
 * Modal component that contains functionality for inserting Client System into the LWR database.
 * This data insertion is done via a POST request to the LWR API.
 *
 * Contains insert form and assosciated validation.
 *
 * Author: Oscar Jaimes
 */

import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form, Alert, Row, Col } from "react-bootstrap";

// api
import { createSystemSite } from "../../../api/systemSites";
import { getAllSysTypes } from "../../../api/systemtypes";
import { getAllFarms } from "../../../api/farms";

export default function SystemsInsertModal(props) {
  // States
  const [show, setShow] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  // parent farms
  const [farmElements, setFarmElements] = useState([]);
  // system types
  const [systemTypeElements, setSystemTypeElements] = useState([]);

  // Selects
  const [parentFarm, setParentFarm] = useState("");
  const [systemType, setSystemType] = useState("F");
  const [manureType, setManureType] = useState("Dairy");

  // Refs
  const systemNameRef = useRef();
  const jobNumberRef = useRef();
  const linkingIdRef = useRef();

  // Shows
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setErrMsg("");
    setSuccessMsg("");
    props.onEnd();
  };

  useEffect(() => {
    loadParentFarms();
    loadSystemTypes();
  }, []);

  const loadParentFarms = async () => {
    const allFarms = await getAllFarms();
    setParentFarm(allFarms[0].name);
    setFarmElements(
      allFarms.map((farm) => {
        return <option>{farm.name}</option>;
      })
    );
  };

  const loadSystemTypes = async () => {
    const sysTypes = await getAllSysTypes();
    setSystemTypeElements(
      sysTypes.map((system) => {
        return <option>{system.systemName}</option>;
      }));
    setSystemType(sysTypes[0].systemName);
  };

  const clearForm = () => {
    systemNameRef.current.value = "";
    jobNumberRef.current.value = "";
    linkingIdRef.current.value = "";
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    let farmId = parentFarm;
    let systemName = systemNameRef.current.value;
    let sysType = systemType;
    let jobNumber = jobNumberRef.current.value;
    let linkingId = linkingIdRef.current.value;
    let manType = manureType;


    console.log(farmId, systemName, sysType, jobNumber, linkingId, manType);

    const createResult = await createSystemSite(farmId, systemName, sysType, jobNumber, linkingId, manType);

    if (createResult.status != 200) {
      setSuccessMsg("");
      setErrMsg("Error registering new client system");
    } else {
      setErrMsg("");
      setSuccessMsg(
        `Succesfully inserted client system: ${systemName} into the database`
      );
      props.onEnd();
      clearForm();
    }

    e.preventDefault();
  };

  const onParentFarmChange = (e) => {
    setParentFarm(e.target.value);
  };

  const onSystemTypeChange = (e) => {
    setSystemType(e.target.value);
  };

  const onManureTypeChange = (e) => {
    setManureType(e.target.value);
  };

  return (
    <>
      <Button style={{ backgroundColor: '#005E7D' }} onClick={handleShow}>
        Add New Client System
      </Button>

      <Modal show={show} onHide={handleClose} size="lg" style={{ zoom: '85%' }}>
        <Modal.Header>
          <Modal.Title style={{ color: '#005E7D' }}>Add New Client Sytem</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {errMsg && <Alert variant="danger">{errMsg}</Alert>}
            {successMsg && <Alert variant="success">{successMsg}</Alert>}
            <Form onSubmit={onFormSubmit}>
              <Form.Group>
                <Form.Label style={{ color: '#005E7D' }}>System Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter System name"
                  ref={systemNameRef}
                  required
                />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label style={{ color: '#005E7D' }}>Parent Farm</Form.Label>
                <Form.Control as="select" onChange={onParentFarmChange}>
                  {farmElements}
                </Form.Control>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label style={{ color: '#005E7D' }}>System Type</Form.Label>
                <Form.Control as="select" onChange={onSystemTypeChange}>
                  {systemTypeElements}
                </Form.Control>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label style={{ color: '#005E7D' }}>Manure Type</Form.Label>
                <Form.Control as="select" onChange={onManureTypeChange}>
                  <option>Dairy</option>
                  <option>Hog</option>
                  <option>Digestate</option>
                  <option>Poultry</option>
                </Form.Control>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label style={{ color: '#005E7D' }}>Linking ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Linking Id"
                  ref={linkingIdRef}
                  required
                />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label style={{ color: '#005E7D' }}>Job #</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Job #"
                  ref={jobNumberRef}
                  required
                />
              </Form.Group>
              <br />
              <Row>
                <Col>
                  <Button variant="outline-dark" type="submit">
                    Update
                  </Button>
                </Col>
                <Col style={{ textAlign: 'right' }}>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
