import { useEffect, useState, useRef, Fragment } from 'react';
import { Card, Form, Row, Col, Table, Button, Modal, Spinner, Alert } from 'react-bootstrap';
import { useAuth } from "../../contexts/AuthContext";
import { RiDeleteBin5Line, RiPlayListAddLine } from 'react-icons/ri';
import { IoMdRemoveCircleOutline } from 'react-icons/io';
import { getUserData } from '../../api/admin';
import Select from "react-select";

// api
import { getJobCodes, projectNameByJobCode, getTasksForProject } from '../../api/zoho';
import { approveTimesheet, createNewShopTimesheet, getShopTimesheet, singleDownload, updateShopTimesheet } from '../../api/hrShopTimeSheet';
import { getUserPermissionFromCache, isPermissionTypeHRAdmin, isPermissionTypeShopEmployee, isPermissionTypeFieldEmployee } from '../../utility/permissionFunctions';

const ShopTimesheetForm = props => {
  // viewing mode
  const isViewMode = props.isViewMode;
  const timesheet = props.timesheet;
  const editProp = props.editMode;

  // hooks
  const { email } = useAuth().currentUser;

  // constants
  const jobCodes = [
    'Control Panel',
    'Floc Tank',
    'groControl',
    'RO System',
    'Microscreen',
    'Polymer System',
    'Steel Skids',
    'Clarifier',
    'Equipment Mounts',
    'RO Rack',
    'Guardian Grip',
    'Filter Skid',
    'Oil Water Separator',
    'Shipping/Recieving',
    'Overhead (meetings, cleaning shop, organizing shop, non-job tasks)',
    'Other (R&D, Warranty work, IWR jobs, misc. tasks)',
    'Vacation Pay',
    'Sick Pay',
    'Statutory Holiday',
    'Unpaid Time Off',
  ]

  const subTasks = [
    'Welding',
    'Plastic Welding',
    'Assembly / Equipment Placement',
    'Pipe Fitting',
    'Testing Equipment',
    'Loading Equipment for Shipping',
    'Get Equipment Ready for Shipping',
    'Electrical Wiring',
    'N/A'
  ];

  // state
  const [timeSheetName, setTimesheetName] = useState("");
  const [jobNumberOptions, setJobNumberOptions] = useState([]);
  const [jobNumbers, setJobNumbers] = useState([{ id: '', tasks: [{ name: '', hours: '', subtask: '', zohoTaskId: '' }] }]);
  const [loading, setLoading] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [uploadResult, setUploadResult] = useState('true');
  const [totalHoursWorked, setTotalHoursWorked] = useState(0);
  const [errors, setErrors] = useState({});
  const [timesheetToView, setTimesheetToView] = useState({date: `${new Date().toISOString()}`, email: ''});
  const [timesheetDate, setTimesheetDate] = useState(new Date());
  const [viewJobList, setViewJobList] = useState([]);
  const [employeeName, setEmployeeName] = useState("");
  const [userType, setUserType] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [employeeNumber, setEmployeeNumber] = useState("");
  const [employeeInitials, setEmployeeInitials] = useState("");
  const [projectNameMap, setProjectNameMap] = useState({});
  const [taskOptions, setTaskOptions] = useState({});
  const [notes, setNotes] = useState("");

  // effects
  useEffect(async () => {
    document.title = 'Shop Timesheets';
    // Get employee name from email address
    const {firstName, lastName, employeeNumber} = await getUserData(email);
    const employeeNameFromEmail = `${firstName} ${lastName}`
    setEmployeeName(employeeNameFromEmail);
    setEmployeeNumber(employeeNumber);
    setTimesheetName(`${employeeNameFromEmail} - ${new Date().toDateString()}`);
    setUserType(await getUserPermissionFromCache(email));
  }, []);

  useEffect(async () => {
    const jobNumberRes = await getJobCodes();
    setJobNumberOptions(jobNumberRes.filter((job) => { return job.jobNumber}));
  }, []);

  // edit hook
  useEffect(() => {
    if (editProp) {
      setEditMode(true);
    }
  }, []);

  // view/edit mode effect
  useEffect(async () => {
    if (isViewMode) {
      const ts = await getShopTimesheet(timesheet._id, timesheet.email);
      setTimesheetToView(ts);
      setEmployeeNumber(ts.empNumber);
      setEmployeeInitials(ts.jobList[0].jobCodeList[0].employeeInitial);
      setViewJobList(ts.jobList);
      setNotes(ts.notes);

      // update state with results from API for editing purposes
      const jobNumberTasks = ts.jobList.map((j) => {
        return {
          id: j.jobNumber,
          tasks: j.jobCodeList.map((jc) => ({
            subtask: jc?.subtask || '',
            name: jc.jobCode,
            hours: jc.hoursWorked,
            notes: jc.notes,
            zohoTaskId: jc.zohoTaskId,
            date: jc.date
          }))
        };
      });

      setJobNumbers(jobNumberTasks);
      updateHoursWorked();
      let totalHours = 0;
      jobNumberTasks.forEach((job) => {
        job.tasks.forEach((task) => {
          totalHours += Number(task.hours);
        })
      });
      setTotalHoursWorked(totalHours);
      setTimesheetDate(new Date(ts.date.split('T')[0]));
      setTimesheetName(ts.shopTimesheetName);
      setNotes(notes);
    }
  }, [editMode]);

  useEffect(async () => {
    if (isViewMode) {
      const ts = await getShopTimesheet(timesheet._id, timesheet.email);
      let projectMap = {};
      for (let i = 0; i < ts.jobList.length; i++) {
        const j = ts.jobList[i];
        const projectName = await projectNameByJobCode(j.jobNumber);
        projectMap[j.jobNumber] = projectName;
      }

      setProjectNameMap(projectMap);
    }
  }, []);

  // refs
  const timesheetRef = useRef();

  // funcs
  function capitalizeFirstLetterOfEachWord(str) {
    return str.replace(/\b\w/g, function (c) { return c.toUpperCase(); });
  }

  const addNewJob = (e) => {
    e.preventDefault();
    setJobNumbers([...jobNumbers, { id: '', tasks: [{ name: '', hours: '' }] }]);
    updateHoursWorked();
  };

  const addNewTask = (e, index) => {
    e.preventDefault();
    const newJobNumbers = [...jobNumbers];
    newJobNumbers[index].tasks.push({ name: '', hours: '' });
    setJobNumbers(newJobNumbers);
    updateHoursWorked();
  };

  const handleJobNumberChange = async (event, index) => {
    const newJobNumbers = [...jobNumbers];
    const { projectId, label } = (event.target.value);
    newJobNumbers[index].id = event.target.value;
    setJobNumbers(newJobNumbers);
    updateHoursWorked();

    // fetch task options for selected job number
    const specificTasksForProject = await getTasksForProject(projectId);
    const newTaskOpts = {
      ...taskOptions
    };
    newTaskOpts[label] = specificTasksForProject;
    setTaskOptions(newTaskOpts);
  };

  const handleTaskChange = (event, jobIndex, taskIndex, field) => {
    const newJobNumbers = [...jobNumbers];
    if(field === 'subtask'){
      if(event.target.value === 'Select Task') return;
      const zohoTaskId = taskOptions[jobNumbers[jobIndex].id.label].find((t) => t.task === event.target.value).id;
      newJobNumbers[jobIndex].tasks[taskIndex][field] = event.target.value;
      newJobNumbers[jobIndex].tasks[taskIndex]['zohoTaskId'] = zohoTaskId;
    } else {
      newJobNumbers[jobIndex].tasks[taskIndex][field] = event.target.value;
    }
    setJobNumbers(newJobNumbers);
    updateHoursWorked();
  };

  const deleteJob = (index) => {
    let newJobNumbers = [...jobNumbers];
    newJobNumbers.splice(index, 1);
    setJobNumbers(newJobNumbers);
    updateHoursWorked();
  };

  const deleteTask = (jobIndex, taskIndex) => {
    let newJobNumbers = [...jobNumbers];
    newJobNumbers[jobIndex].tasks.splice(taskIndex, 1);
    setJobNumbers(newJobNumbers);
    updateHoursWorked();
  };

  const updateHoursWorked = () => {
    let totalHours = 0;
    jobNumbers.forEach((job) => {
      job.tasks.forEach((task) => {
        totalHours += Number(task.hours);
      })
    });

    setTotalHoursWorked(totalHours);
  }


  const validateForm = () => {
    let isValid = true;
    let validationErrors = {};

    jobNumbers.forEach((job, index) => {
      // Check if a job number has been chosen
      if (!job.id || job.id === 'Click To Select Job Number') {
        isValid = false;
        validationErrors[`jobNumber_${index}`] = "Please select a job number.";
      }

      // Check if tasks have been selected
      job.tasks.forEach((task, taskIndex) => {
        if (!task.hours) {
          isValid = false;
          validationErrors[`task_${index}_${taskIndex}`] = "Please select a task and provide hours.";
        }

        if (!task.subtask) {
          isValid = false;
          validationErrors[`subtask_${index}_${taskIndex}`] = "Please select a sub task.";
        }
      });
    });

    let totalHours = 0;
    jobNumbers.forEach((job) => {
      job.tasks.forEach((task) => {
        if (task.permissionType !== "employee") {
          totalHours += Number(task.hours);
        }
      });
    });
    
    if (totalHours > 8 && !isPermissionTypeFieldEmployee(userType)) {
      isValid = false;
      validationErrors['totalHours'] = "Please check total hours worked. Total hours worked cannot exceed 8.";
    }

    // set error state so we can show it in the form
    setErrors(validationErrors);
    return isValid;
  };

  const submitTimesheet = async (e) => {
    e.preventDefault();

    // Validate form before submission
    if (!validateForm()) { console.log('form validation error'); return };

    setLoading(true);
    const jobList = jobNumbers.map((job) => {
      return {
        jobNumber: job.id.value,
        jobCodeList: job.tasks.map((task) => {
          return {
            jobCode: task.name,
            subtask: task.subtask,
            hoursWorked: task.hours,
            employeeInitial: employeeInitials,
            zohoTaskId: task.zohoTaskId,
            notes: task.notes,
          }
        })
      };
    });

    // get hours worked
    let totalHours = 0;
    jobNumbers.forEach((job) => {
      job.tasks.forEach((task) => {
        totalHours += Number(task.hours);
      })
    });

    const timesheetBody = {
      shopTimesheet: {
        shopTimesheetName: timeSheetName,
        date: new Date(timesheetDate).toISOString().split('T')[0],
        empNumber: employeeNumber,
        totalHours: totalHours,
        jobList: jobList,
      },
      email: email
    };

    // new submission
    if (!editMode) {
      const uploadResult = await createNewShopTimesheet(timesheetBody);
      if (uploadResult.status === 200) {
        setUploadResult('Succesfully Uploaded Timesheet');
      } else {
        if(uploadResult.response.data.includes('E11000')) {
          setUploadResult(`Error. There already exists a timesheet for: ${timesheetBody.shopTimesheet.shopTimesheetName}`)
        } else {
          setUploadResult('Could Not Successfully Upload Timesheet. Please try again.')
        }
      }
    } else {
      // editing timesheet
      const body = {
        updatedTimesheet: {
          _id: timesheetToView._id,
          shopTimesheetName: timeSheetName,
          date: new Date(timesheetDate).toISOString().split('T')[0],
          empNumber: employeeNumber,
          totalHours: totalHours,
          jobList: jobList,
          notes: notes
        },
        email: email
      };

      const updateResult = await updateShopTimesheet(body);
      if (updateResult.status === 200) {
        setUploadResult('Succesfully Updated Timesheet');
      } else {
        setUploadResult('Could Not Successfully Update Timesheet. Please try again.')
      }

      setEditMode(false);
    }
    setLoading(false);
    setShowResultModal(true);
  }

  const approve = async () => {
    setLoading(true);
    // make request to approve timesheet (this approves in LWR DB + Submits timesheet to Zoho)
    const res = await approveTimesheet(timesheet._id, email);
    setLoading(false);

    // show result modal
    setShowResultModal(true);
    if(res.status === 200) {
      setUploadResult('Succesfully approved timesheet and uploaded to Zoho.');
      const newTimesheet = await getShopTimesheet(timesheet._id, timesheet.email);
      setTimesheetToView(newTimesheet);
    } else {
      setLoading(false);
      setUploadResult('Encountered error approving timesheet. Please try again. If the problem persists, contact the development team.');
    }
  }; 

  return (
    <div style={{ zoom: '90%', height: '100%' }}>
      <Modal show={loading} size="md">
        <Modal.Header>
          <Modal.Title>Uploading Timesheet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
      <Modal show={showResultModal} size="md">
        <Modal.Header>
          <Modal.Title>Upload Result</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           <Alert variant={uploadResult.includes('Error') ? 'danger' : 'success'}>{uploadResult}</Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {props.onFinish(); setShowResultModal(false);}}>Close</Button>
        </Modal.Footer>
      </Modal>
      {isViewMode &&
        <Row style={{ marginTop: '10px' }}>
          <Col style={{ textAlign: 'left' }}>
            <Button variant='outline-dark' onClick={props.cancelView}>Close Timesheet</Button>
          </Col>
          <Col style={{textAlign: 'center'}}>
            {isViewMode && <h4 style={{color: '#005E7D'}}>{timesheetToView.shopTimesheetName} - Shop Timesheet</h4>}
          </Col>
          <Col style={{ textAlign: 'right' }}>
            <Button variant='outline-success' onClick={async () => {
              await singleDownload(timesheet._id);
            }}>Download Timesheet</Button>
            {timesheetToView.email === email && <Button
              style={{marginLeft: '1%'}}
              variant={!editMode ? 'outline-secondary' : 'secondary'}
              onClick={() => { setEditMode(!editMode) }}
            >
              {!editMode ? 'Edit Timesheet' : 'Cancel Edit'}
            </Button>}
          </Col>
        </Row>
      }
      {
        (isViewMode) &&
        <Alert variant={timesheetToView.approved ? 'success' : 'warning'} className='mt-3'>
          <Row>
            <div>
              Timesheet Approved: {timesheetToView.approved ? 'Yes' : 'No'}
            </div>
            <div>
              {(!timesheetToView.approved && (isPermissionTypeHRAdmin(userType))) &&
                <Button variant='outline-dark' onClick={approve}>
                  Approve Timesheet
                </Button>
              }
              {
                timesheetToView.approved &&
                <>
                  {`Approved By: ${timesheetToView.approvedBy}`}
                </>
              }
            </div>
          </Row>
        </Alert>
      }
      <Card style={{ marginTop: '1%' }}>
        <Card.Body ref={timesheetRef}>
          <Form onSubmit={submitTimesheet}>
            <Row style={{ marginTop: '0%' }}>
              <Col md={12} style={{ textAlign: 'left' }}>
                <h5>Employee Information</h5>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Timesheet Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={timeSheetName}
                    disabled
                  />
                </Form.Group>
                <br />
                <Form.Group>
                  <Form.Label>Employee Number</Form.Label>
                  <Form.Control
                    type="number"
                    step={1}
                    value={employeeNumber}
                    onChange={(e) => setEmployeeNumber(e.target.value)}
                    disabled={isViewMode && !editMode}
                    required
                  />
                </Form.Group>
                <br />
                <Form.Group>
                  <Form.Label>Total Hours Worked (Calculated From Below)</Form.Label>
                  <Form.Control
                    type="number"
                    value={totalHoursWorked}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={new Date(timesheetDate).toISOString().split('T')[0]}
                      disabled={isViewMode && !editMode}
                      onChange={(e) => {
                        const dateValue = e.target.value;
                        const localDate = new Date(dateValue + 'T00:00:00');
                        setTimesheetName(employeeName + ` - ${localDate.toDateString()}`);
                        setTimesheetDate(localDate);
                      }}
                      required
                    />
                </Form.Group>
                <br />
                <Form.Group>
                  <Form.Label>Employee Initial</Form.Label>
                  <Form.Control
                    type="text"
                    value={employeeInitials}
                    onChange={(e) => setEmployeeInitials(e.target.value)}
                    disabled={isViewMode && !editMode}
                    required
                  />
                  <div
                    className="text-center border rounded p-2"
                    style={{ background: "rgba(0, 0, 0, 0.03)" }}
                  >
                    By writing my initals, I confirm that I am completing this timesheet for myself and have completed the below hours.
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ marginTop: '1.5%' }}>
              <hr />
              <Col xs={8} style={{ textAlign: 'left' }}>
                <h5>Jobs & Respective Tasks</h5>
              </Col>
              {(!isViewMode || editMode) && <Col xs={4} style={{ textAlign: 'right' }}>
                <Button variant="outline-dark" onClick={addNewJob}>Add Job</Button>
              </Col>}
            </Row>
            {!isViewMode && <Row style={{ paddingTop: '10px', margin: '5px' }}>
              <div
                className="text-center border rounded p-2 mr-2"
                style={{ background: "rgba(0, 0, 0, 0.03)" }}
              >
                Add tasks and respective hours worked for each job. Press the  <RiPlayListAddLine size={20} /> icon to add multilple task entries. Click the 'Add Job' button to add multiple jobs.
              </div>
            </Row>}
            <Row>
              {jobNumbers.map((job, jobIndex) => (
                <Col md={12}>
                  <Card style={{ marginTop: '10px' }}>
                    <Card.Header style={{ padding: '5px', backgroundColor: '#005E7D' }}>
                      <Row>
                        <Col xs={6} style={{ textAlign: 'left' }}>
                          <Form.Group>
                              {(isViewMode && !editMode) ? 
                              <input type="text" value={`Job Number: ${projectNameMap[job.id] || job.id}`} disabled style={{color: 'white', backgroundColor: '#005E7D', width: '100%'}}/>
                              :<Select
                                required
                                value={job.id}
                                placeholder="Select a job"
                                options={jobNumberOptions.map((job) => ({
                                    projectId: job.projectId,
                                    value: job.jobNumber,
                                    label: `${job.projectName}`
                                  })
                                )}
                                onChange={(value) => {
                                  handleJobNumberChange({target: {value}}, jobIndex)
                                }}
                              />}
                          </Form.Group>
                        </Col>
                        <Col xs={6} style={{ textAlign: 'right' }}>
                        {(!isViewMode || editMode) && <Button variant="outline-light" onClick={() => deleteJob(jobIndex)}>
                            <RiDeleteBin5Line size={25} />
                          </Button>}
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body style={{ padding: 8, margin: 0 }}>
                      <Fragment key={jobIndex}>
                        <Table className="job-table" striped>
                          <thead>
                            <tr>
                              <th style={{width: '33%'}}>Project Tasks</th>
                              <th style={{width: '33%'}}>Hours Worked</th>
                              <th style={{width: '33%'}}>Notes</th>
                              <th>
                                {(!isViewMode || editMode) && <Button variant='outline-secondary' style={{ padding: 3 }} onClick={(e) => addNewTask(e, jobIndex)}>
                                  <RiPlayListAddLine size={25} />
                                </Button>}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {!job.id && 
                            <tr>
                                <td colSpan={3}>
                                <Alert>Select a job before selecting tasks</Alert>
                                </td>
                              </tr>}
                            {job.id && job.tasks.map((task, taskIndex) => (
                              <tr key={taskIndex}>
                                <td>
                                  <Form.Control
                                    as="select"
                                    value={task.subtask}
                                    onChange={(e) => handleTaskChange(e, jobIndex, taskIndex, 'subtask')}
                                    isInvalid={errors[`subtask_${jobIndex}_${taskIndex}`]}
                                    disabled={(isViewMode && !editMode) || !taskOptions[job.id.label]}
                                    required
                                  >
                                    {(isViewMode && !editMode) && <option key="projectTaskSelect">{task.subtask}</option>}
                                    {(!taskOptions[job.id.label]) && <option>No available tasks</option>}
                                    <option key="projectTaskSelect">Select Task</option>
                                    {(taskOptions[job.id.label] || []).map((t) => (
                                      <option key={t.task} value={t.task}>
                                        {t.task}
                                      </option>
                                    ))}
                                  </Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    Job Task Must Be Selected
                                  </Form.Control.Feedback>
                                </td>
                                <td>
                                  <Form.Control placeholder="Hours Worked" type="number" value={task.hours} 
                                    disabled={isViewMode && !editMode}
                                    onChange={(e) => handleTaskChange(e, jobIndex, taskIndex, 'hours')} required 
                                    isInvalid={errors[`task_${jobIndex}_${taskIndex}`] ||
                                    (totalHoursWorked > 8 && !isPermissionTypeFieldEmployee(userType))} />
                                    <Form.Control.Feedback type="invalid">
                                      {errors[`task_${jobIndex}_${taskIndex}`] || "Shop Hours Must Not Exceed 8 Hours"}
                                    </Form.Control.Feedback>
                                </td>
                                <td>
                                  <Form.Control
                                    placeholder="Notes"
                                    type="text"
                                    value={task.notes || ""} 
                                    onChange={(e) => handleTaskChange(e, jobIndex, taskIndex, 'notes')}
                                    disabled={isViewMode && !editMode}
                                    required={false}
                                  />
                                </td>
                                <td style={{ textAlign: 'left' }}>
                                  {(!isViewMode || editMode) && <Button variant='outline-danger' style={{ margin: 0, padding: 1 }} onClick={() => deleteTask(jobIndex, taskIndex)} >
                                    <IoMdRemoveCircleOutline size={30} />
                                  </Button>}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div style={{ marginBottom: '1em' }} />
                      </Fragment>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row>
              <Col style={{ marginTop: '15px', textAlign: 'left' }} xs={11}>
                {!isViewMode && <Button type="submit" variant='outline-dark'>Submit Timesheet</Button>}
                {editMode &&  <Button type="submit" variant='outline-dark'>Update Timesheet</Button>}
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ShopTimesheetForm;