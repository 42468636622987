import { useState, useEffect, useRef } from 'react';
import { Button, Form, Row, Col, Card, Table, Modal, Alert } from 'react-bootstrap';
import { useParams, useHistory } from "react-router-dom";
import Select from 'react-select';
import { useAuth } from "../../contexts/AuthContext";
import "./workOrderForm.css";
import useQuery from '../../hooks/useQuery';

// api
import {
  createWorkOrder, updateWorkOrder, getWorkOrder,
  getAllJobNumbers, getMetadataForJob,
  getNextJobNumber, getAllAssetIds, getAssetClassess, getNextWONumber
} from '../../api/workOrders';
import { getAssetProjects } from '../../api/zoho';
import { createOrganization } from '../../api/organizations';
import { createFarm } from '../../api/farms';
import { createSystemSite } from '../../api/systemSites';

const WorkOrderForm = ({ mode, id, onSubmitEnd = () => { } }) => {
  const [isViewMode, setIsViewMode] = useState(mode === 'view');
  const [isEditMode, setIsEditMode] = useState(mode === 'edit');
  const { currentUser } = useAuth();
  const history = useHistory();
  let { workOrderId } = useParams();
  if (!workOrderId) { workOrderId = id;}

  // data hooks
  const {
    data: assets = [],
  } = useQuery(
    'assets',
    () => getAssetProjects(),
    null
  );

  // state
  const [jobNumbers, setJobNumbers] = useState([]);
  const [uniqueWONumber, setUniqueWONumber] = useState("");
  const [newJobNumber, setNewJobNumber] = useState(null);
  const [isNewJobNumber, setIsNewJobNumber] = useState(false);
  const [showSubmitSuccess, setShowSubmitSuccess] = useState(false);
  const [showTransferSubmitSuccess, setShowTransferSubmitSuccess] = useState(false);
  const [transferJobNumber, setTransferJobNumber] = useState('');
  const [errors, setErrors] = useState({});
  const [paymentTerms, setPaymentTerms] = useState("");
  const [revisionComments, setRevisionComments] = useState("");
  const [showConfirmTransferWOModal, setShowConfirmTransferWOModal] = useState(false);
  const [woTransferInfo, setWoTransferInfo] = useState(false);
  const [assetId, setAssetId] = useState(null);
  const [availableAssetIds, setAvailableAssetIds] = useState([]);
  const [assetClasses, setAssetClasses] = useState([]);
  const [assetClass, setAssetClass] = useState('');
  const [createdWO, setCreatedWO] = useState(null);
  const [orders, setOrders] = useState([
    { qty: 0, description: '', internalNotes: '', unitPrice: '$0.00', totalPrice: '0', ctj: false, billable: false, purchaseCurrency: 'CAD', cost: false, transfer: false, transferFrom: '' },
  ]);

  const [workOrderToView, setWorkOrderToView] = useState({
    dateOfOrder: new Date(),
    deliveryDate: new Date()
  });
  const [orderDetails, setOrderDetails] = useState({
    jobNumber: '',
    dateOfOrder: new Date(),
    deliveryDate: new Date(),
    billTo: '',
    shipTo: '',
    attention1: '',
    attention2: '',
    telephone1: '',
    telephone2: '',
    email1: '',
    email2: '',
    poNumber: '',
    technician: '',
    billingCurrency: '',
  });

  const fetchAllJobNumbers = async () => {
    const res = await getAllJobNumbers();
    setJobNumbers(res.data);
  };

  useEffect(() => {
    const faid = async () => {
      const ids = await getAllAssetIds();
      setAvailableAssetIds(ids);
    }

    const fac = async () => {
      const acs = await getAssetClassess();
      setAssetClasses(acs);
    }

    faid();
    fac();
  }, []);

  function formatDate(date) {
    const options = { month: 'short', day: '2-digit' };
    return date.toLocaleDateString('en-US', options).replace(/ /g, '').toUpperCase();
  }

  function extractJobId(jobNumber) {
    const patterns = [
      '- BILLABLE',
      '- Billable',
      ' (Internal)',
      ' - TRANSFER'
    ];

    for (let pattern of patterns) {
      jobNumber = jobNumber.split(pattern)[0];
    }

    const parts = jobNumber.split('-');
    return parts.length > 1 ? parts[1] : parts[0];
  }

  async function fetchNextWONumber() {
    try {
      return await getNextWONumber();
    } catch (error) {
      return '';
    }
  }

  const appendToUniqueWONumber = (suffix) => {
    setUniqueWONumber((prev) => {
      const parts = prev.split("-");
      const currentSuffix = parts.pop();
      const isValidSuffix = ["S", "C"].includes(currentSuffix);
  
      const base = isValidSuffix ? parts.join("-") : prev;
      return `${base}-${suffix}`;
    });
  };

  const appendToJobNumber = (suffix) => {
    setOrderDetails((prev) => ({
      ...prev,
      jobNumber: `${prev.jobNumber}-${suffix}`,
    }));
  };
  
  useEffect(() => {
    async function generateUniqueWONumber() {
      if (!isViewMode && !isEditMode) {
        try {
          const nextWONumber = await fetchNextWONumber();
          const dateId = formatDate(orderDetails.dateOfOrder);
          const jobId = extractJobId(orderDetails.jobNumber);
  
          const currentSuffix = uniqueWONumber.split("-").pop();
          const isValidSuffix = ["S", "C"].includes(currentSuffix);
  
          const initialUniqueNumber = isValidSuffix
            ? `${nextWONumber}${dateId}-${jobId}-${currentSuffix}`
            : `${nextWONumber}${dateId}-${jobId}`;
  
          setUniqueWONumber(initialUniqueNumber);
          console.log("Generated unique number:", initialUniqueNumber);
        } catch (error) {
          console.error("Error generating unique work order number:", error);
        }
      } else {
        setUniqueWONumber(workOrderToView.uniqueId);
      }
    }

    generateUniqueWONumber();
  }, [orderDetails]);

  useEffect(async () => {
    if (isViewMode || isEditMode) {
      const res = await getWorkOrder(workOrderId);
      const wo = res.data;
      setWorkOrderToView(wo);
      setOrderDetails(wo);
      setOrders(wo.orders);
      setPaymentTerms(wo.paymentTerms);
    }
    fetchAllJobNumbers();
  }, []);

  const addOrder = () => {
    setOrders([...orders, {
      qty: 0,
      description: '',
      internalNotes: '',
      unitPrice: '$0.00',
      totalPrice: '0',
      ctj: false,
      cost: false,
      transfer: false,
      transferFrom: '',
      purchaseCurrency: 'CAD',
    }]);
  };

  const removeOrder = (indexToRemove) => {
    setOrders(orders.filter((_, index) => index !== indexToRemove));
  };

  const handleCTJChange = (indexToChange) => {
    setOrders(orders.map((order, index) =>
      index === indexToChange ? { ...order, ctj: !order.ctj, cost: false, unitPrice: '$0.00', totalPrice: '0' } : order
    ));
  };

  const handleBillableChange = (indexToChange) => {
    setOrders(orders.map((order, index) =>
      index === indexToChange ? { ...order, billable: !order.billable } : order
    ));
  };

  const handleCostCheckbox = (indexToChange) => {
    setOrders(orders.map((order, index) =>
      index === indexToChange ? { ...order, cost: !order.cost, unitPrice: order.cost ? '$0.00' : 'Cost + 15%', totalPrice: order.cost ? '0' : 'Cost + 15%', ctj: false } : order
    ));
  };

  const handleTransferChange = (indexToChange) => {
    setOrders(orders.map((order, index) =>
      index === indexToChange ? { ...order, transfer: !order.transfer, transferFrom: order.transfer ? '' : order.transferFrom } : order
    ));
  };

  const handleTransferJobChange = (indexToChange, transferFrom) => {
    setOrders(orders.map((order, index) =>
      index === indexToChange ? { ...order, transferFrom } : order
    ));
  };

  const handleInputChange = (indexToChange, field, newValue) => {
    setOrders(orders.map((order, index) => {
      if (index === indexToChange) {
        // update totalPrice when unitPrice changes
        if (field === 'unitPrice' && !order.cost) {
          const qty = order.qty > 0 ? order.qty : 1;
          const totalPrice = '' + (qty * parseFloat(newValue.replace('$', '').replaceAll(',', '').trim()));
          return { ...order, [field]: newValue, totalPrice };
        }

        // update totalPrice when qty changes
        if (field === 'qty' && !order.cost) {
          const qty = Number(newValue) > 0 ? Number(newValue) : 1;
          const totalPrice = '' + (qty * parseFloat(order.unitPrice.replace('$', '').replaceAll(',', '').trim()));
          return { ...order, [field]: newValue, totalPrice };
        }

        return { ...order, [field]: newValue }
      }

      return order;
    }));
  };

  const handleOrderDetailsChange = async (field, newValue) => {
    if (field === 'jobNumber' && newValue === 'New Job Number') {
      setIsNewJobNumber(true);
      if (!isNewJobNumber) {
        const newJN = await getNextJobNumber();
        setNewJobNumber(newJN);
        setOrderDetails({
          ...orderDetails,
          jobNumber: newJN
        });
      }
    } else if (field === 'jobNumber' && newValue !== 'New Job Number') {
      const metadata = await getMetadataForJob(newValue);
      setOrderDetails({
        ...orderDetails,
        billTo: metadata.billTo,
        shipTo: metadata.shipTo,
        attention1: metadata.att[0],
        attention2: metadata.att[1],
        telephone1: metadata.tel[0],
        telephone2: metadata.tel[1],
        email1: metadata.email[0],
        email2: metadata.email[1],
        jobNumber: newValue
      });
    } else {
      setOrderDetails({ ...orderDetails, [field]: newValue });
    }
  };

  const workOrderSubmit = async (event) => {
    event.preventDefault();
    let errors = {};
    let hasError = false;
    Object.keys(orderDetails).forEach((key) => {
      if (key === 'technician' || key === 'poNumber') {
        return;
      } else if (key === 'jobNumber') {
        if (orderDetails.jobNumber === ' ' || !orderDetails[key]) {
          errors[key] = `Required`;
          hasError = true;
        }
      } else if (!orderDetails[key]) {
        errors[key] = `Required`;
        hasError = true;
      }
    });

    if (orders.length == 0) {
      alert('Order must contain at least one item');
      return;
    }
    // validation for orders
    orders.forEach((order, index) => {
      if (!order.qty) {
        errors[`qty-${index}`] = 'Quantity is required.';
        hasError = true;
      }

      if (!order.description) {
        errors[`description-${index}`] = 'Description is required.';
        hasError = true;
      }

      if (!order.ctj && !order.cost) {
        const up = order.unitPrice.replace('$', '').replaceAll(',', '').trim()
        if (!order.unitPrice || isNaN(Number(up)) || Number(up) <= 0) {
          errors[`unitPrice-${index}`] = 'Unit Price is invalid.';
          hasError = true;
        }
      }
    });

    if (hasError) {
      setErrors(errors);
      if (!Object.keys(errors).includes('__v') && Object.keys(errors).length >= 1) { return; }
    } else {
      setErrors({});
    }

    const cworkOrder = {
      newJobNumber: !!newJobNumber,
      uniqueId: uniqueWONumber,
      jobNumber: orderDetails.jobNumber,
      orderedBy: currentUser.email,
      dateOfOrder: orderDetails.dateOfOrder,
      deliveryDate: orderDetails.deliveryDate,
      billTo: orderDetails.billTo,
      shipTo: orderDetails.shipTo,
      attention1: orderDetails.attention1,
      attention2: orderDetails.attention2,
      telephone1: orderDetails.telephone1,
      telephone2: orderDetails.telephone2,
      email1: orderDetails.email1,
      email2: orderDetails.email2,
      poNumber: orderDetails.poNumber,
      technician: orderDetails.technician,
      orders: orders,
      paymentTerms,
      revisionComments,
      isAsset: assetId !== null,
      assetClass,
      assetId,
      billingCurrency: orderDetails.billingCurrency
    };

    // add 'BILLABLE' label to Job Number if any single item in the WO has the billable field set to true
    if (orders.some((o) => o.billable && !cworkOrder.jobNumber.includes('BILLABLE'))) {
      cworkOrder.jobNumber += ' - BILLABLE';
    }

    console.log(cworkOrder);

    // handle work order UPDATE here
    if (isEditMode) {
      delete cworkOrder.orderedBy;
      const r = await updateWorkOrder(cworkOrder, workOrderToView['_id']);
      if (r.status == 200) {
        setShowSubmitSuccess(true);
      } else {
        alert('Error creating work order');
      }
      return;
    }

    // handle work order CREATION here
    const r = await createWorkOrder(cworkOrder);
    if (r.status == 200) {
      setCreatedWO(r.data);
      setShowSubmitSuccess(true);
    } else {
      alert('Error creating work order');
    }

    // handle item transfer between jobs here
    const transfers = orders.filter((o) => o.transfer);
    if (transfers.length >= 1) {
      const transferTable = (<table border="1">
        <thead>
          <tr>
            <th>Item</th>
            <th>Qty</th>
            <th>Order For</th>
          </tr>
        </thead>
        <tbody>
          {transfers.map((order, i) => (
            <tr key={i}>
              <td>{order.description}</td>
              <td>{order.qty}</td>
              <td>{order.transferFrom}</td>
            </tr>
          ))}
        </tbody>
      </table>);
      setWoTransferInfo(transferTable);
      setShowConfirmTransferWOModal(true);
    }

    if (isNewJobNumber) {
      createNewClientWithJobNumber(
        cworkOrder.billTo,
        cworkOrder.shipTo,
        cworkOrder.attention1,
        cworkOrder.attention2,
        cworkOrder.email1,
        cworkOrder.email2,
        cworkOrder.telephone1,
        cworkOrder.telephone2,
        newJobNumber
      );
    }
  };

  const handleTransferWorkOrders = async () => {
    const transfers = orders.filter((o) => o.transfer);
    const nextWONumber = await fetchNextWONumber();

    const currentSuffixMatch = uniqueWONumber.match(/-(S|C)$/i);
    const currentSuffix = currentSuffixMatch ? currentSuffixMatch[0] : ''; 

    transfers.forEach(async (transfer) => {
      const transferJobMetadata = await getMetadataForJob(transfer.transferFrom);

      const jobId = extractJobId(transfer.transferFrom);
      const dateId = formatDate(orderDetails.dateOfOrder);


      const transferWorkOrder = {
        uniqueId: `${nextWONumber}${dateId}-${jobId}${currentSuffix}`,
        jobNumber: `${transfer.transferFrom}${currentSuffix} - TRANSFER`,
        orderedBy: currentUser.email,
        dateOfOrder: orderDetails.dateOfOrder,
        deliveryDate: orderDetails.deliveryDate,
        billTo: transferJobMetadata.billTo,
        shipTo: transferJobMetadata.shipTo,
        attention1: transferJobMetadata.att[0] || '',
        attention2: transferJobMetadata.att[1] || '',
        telephone1: transferJobMetadata.tel[0] || '',
        telephone2: transferJobMetadata.tel[1] || '',
        email1: transferJobMetadata.email[0] || '',
        email2: transferJobMetadata.email[1] || '',
        poNumber: transferJobMetadata.poNumber || ' ',
        technician: '',
        // Order indicating transfer
        orders: [
          {
            qty: transfer.qty,
            description: transfer.description + ` - Replacement Order Due To Transfer to ${orderDetails.jobNumber}`,
            unitPrice: 0,
            totalPrice: 0,
            ctj: true,
            cost: false,
            transfer: false,
            transferFrom: '',
          }],
        paymentTerms
      };

      const r = await createWorkOrder(transferWorkOrder);
      if (r.status == 200) {
        setShowConfirmTransferWOModal(false);
        setTransferJobNumber(transfer.transferFrom);
        setShowTransferSubmitSuccess(true);
      } else {
        alert(`Error Creating Transfer Work Order For Job: ${transfer.transferFrom}`);
      }
    });
  };

  const createNewClientWithJobNumber = async (billTo, shipTo, att, attention2, email, email2, tel, telephone2, jobNumber) => {
    try {
      const orgName = billTo.split('\n')[0];
      const farmName = shipTo.split('\n')[0];

      await createOrganization({
        name: orgName,
        address: shipTo,
        contact: att,
        contactNumber: tel,
        contactEmail: email
      });

      await createFarm({
        regionId: '',
        organizationId: orgName,
        name: farmName,
        numSystems: 1,
        address: shipTo,
        lat: 0,
        long: 0,
        owner: attention2,
        contactNumber: telephone2,
        contactEmail: email2
      });

      await createSystemSite(farmName, `${farmName} System`, 'WO', jobNumber, farmName, 'WO');
    } catch (err) {
      alert('Could not create new client with new job number');
      return null;
    }
  }

  const goBack = () => {
    history.push("/work-orders/");
  };

  const getDateValue = (dateString) => {
    try {
      return new Date(dateString).toISOString().split('T')[0];
    } catch {
      return "";
    }
  }

  const calculateTotal = () => {
    const sumTotalPrices = (orders) => {
      return orders.reduce((total, o) => {
        const price = o.totalPrice.replaceAll('$', '').replaceAll(',', '').trim();
        return total + (isNaN(Number(price)) ? 0 : Number(price));
      }, 0);
    };

    let ordersToProcess = [];
    if (!isViewMode || isEditMode) {
      ordersToProcess = orders;
    } else if (isViewMode && workOrderToView && workOrderToView.orders) {
      ordersToProcess = workOrderToView.orders;
    }

    return sumTotalPrices(ordersToProcess);
  };

  const headerStyle = { backgroundColor: '#005E7D', color: 'white' };

  return (
    <>
      <div style={{ margin: '5px' }}>
        <Modal show={showSubmitSuccess}>
          <Modal.Header>
            <Modal.Title>Success!</Modal.Title>
          </Modal.Header>
          <Modal.Body>Succesfully {isEditMode ? 'Updated' : 'Submitted'} work order</Modal.Body>
          <Modal.Footer>
            <Button variant="outline-success" onClick={() => {
              setShowSubmitSuccess(false);
              onSubmitEnd(createdWO);
            }}>Close</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showTransferSubmitSuccess}>
          <Modal.Header>
            <Modal.Title>Success!</Modal.Title>
          </Modal.Header>
          <Modal.Body>Succesfully submitted TRANSFER work order for {transferJobNumber} </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-success" onClick={() => {
              setShowTransferSubmitSuccess(false);
            }}>Close</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showConfirmTransferWOModal}>
          <Modal.Header>Create Transfer Work Order?</Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                Would you like to create a transfer WO(s) with the following information?
                <br />
                <br />
                {woTransferInfo}
                <br />
                <br />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button variant="outline-dark" onClick={async () => { await handleTransferWorkOrders(); }}>Yes, Create</Button>
              </Col>
              <Col>
                <Button variant="outline-danger" onClick={async () => { setShowConfirmTransferWOModal(false); }}>No, Do Not Create</Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
      {(isViewMode && workOrderToView.newJobNumber) &&
        <Alert variant='success' style={{ marginTop: '1%' }}>
          This is a new Job Number - Please ensure that this Job is added into Zoho Books.
        </Alert>
      }
      <Card style={{ marginTop: '-1.0%' }}>
        <Card.Body>
          <Row>
            <Col>
              <Form onSubmit={workOrderSubmit}>
                {(!isViewMode || isEditMode) &&
                  <Row>
                    <Col md={2}>
                      {isViewMode && <h5 style={{ color: '#005E7D' }}>W/O ID: {workOrderToView.uniqueId}</h5>}
                      {!isViewMode && <h5 style={{ color: '#005E7D' }}>W/O ID: {uniqueWONumber}</h5>}
                    </Col>
                  </Row>
                }
                {
                  (!isViewMode && orderDetails.jobNumber === 'ASSET (Internal)') &&
                  <Row>
                    <Col md={12} className='m-3'>
                      <Alert variant='primary'>If you do not see your desired asset in the list, it must first be created in Zoho Books.</Alert>
                    </Col>
                  </Row>
                }
                <Row>
                  <Col>
                    <Table className="striped-table" >
                      <tbody>
                        {/* First Row */}
                        <tr>
                          <td style={{ fontWeight: 'bold' }}>Job #</td>
                          <td>
                            <Form.Group>
                              {isViewMode && !isEditMode ? (
                                <>
                                  <Form.Control
                                    type="number"
                                    placeholder={workOrderToView.jobNumber}
                                    value={workOrderToView.jobNumber}
                                    disabled
                                    isInvalid={!!errors['jobNumber']}
                                    onChange={(e) => handleOrderDetailsChange('jobNumber', e.target.value)}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors['jobNumber']}
                                  </Form.Control.Feedback>
                                </>
                              ) : (
                                <>
                                  <>
                                    <Form.Control
                                      as="select"
                                      value={workOrderToView && !isEditMode ? workOrderToView.jobNumber : orderDetails.jobNumber}
                                      disabled={isViewMode && !isEditMode}
                                      isInvalid={!!errors['jobNumber']}
                                      onChange={(e) => handleOrderDetailsChange('jobNumber', e.target.value)}
                                    >
                                      <option key="jobNumberSelect">{workOrderToView.jobNumber || 'Select a Job Number'}</option>
                                      <option key="newJobNumber" value={isNewJobNumber ? `${newJobNumber}` : 'New Job Number'}>{isNewJobNumber ? `${newJobNumber}` : 'New Job Number'}</option>
                                      {jobNumbers.map((job) => (
                                        <option key={job._id} value={job.jobNumber}>
                                          {job.jobNumber} - {job.farmId}
                                        </option>
                                      ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      {errors['jobNumber']}
                                    </Form.Control.Feedback>
                                  </>

                                </>
                              )}
                            </Form.Group>
                          </td>
                          <td style={{ fontWeight: 'bold' }}>Ordered By</td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                value={isViewMode ? workOrderToView.orderedBy : currentUser.email}
                                disabled />
                            </Form.Group>
                          </td>
                        </tr>

                        {/* Asset Row (If Applicable) */}
                        {(orderDetails.jobNumber === 'ASSET (Internal)' ||
                          workOrderToView.jobNumber === 'ASSET (Internal)')
                          &&
                          <tr>
                            <td style={{ fontWeight: 'bold' }}>Asset</td>
                            <td colSpan={3}>
                              <Select
                                required
                                isDisabled={isViewMode && !isEditMode}
                                placeholder={isViewMode ? `${workOrderToView.assetClass} ${workOrderToView.assetId}` : assetId}
                                options={(assets || []).map((a) => ({ label: a.project_name, value: a.project_name }))}
                                onChange={(obj) => {
                                  setAssetId(obj.value);
                                }}
                              />
                            </td>
                          </tr>
                        }

                        <tr>
                          <td style={{ fontWeight: 'bold' }}>Tag</td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                as="select"
                                disabled={isViewMode && !isEditMode}
                                onChange={(e) => {
                                  const selectedValue = e.target.value;

                                  if (selectedValue === "STag") {
                                    appendToUniqueWONumber("S");
                                    appendToJobNumber("S");
                                  } else if (selectedValue === "CTag") {
                                    appendToUniqueWONumber("C");
                                    appendToJobNumber("C");
                                  }
                                }}>
                                <option value="">Select Tag</option>
                                <option value="STag">-S</option>
                                <option value="CTag">-C</option>
                              </Form.Control>
                            </Form.Group>
                          </td>
                        </tr>

                        {/* Second Row */}
                        <tr>
                          <td style={{ fontWeight: 'bold' }}>Date Of Order</td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type="date"
                                value={isViewMode && !isEditMode ? getDateValue(workOrderToView.dateOfOrder) : getDateValue(orderDetails.dateOfOrder)}
                                disabled={isViewMode && !isEditMode}
                                onChange={e => handleOrderDetailsChange('dateOfOrder', new Date(e.target.value))} />
                              <Form.Control.Feedback type="invalid">
                                {errors[`dateOfOrder`]}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </td>
                          <td style={{ fontWeight: 'bold' }}>Delivery Date</td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type="date"
                                value={isViewMode && !isEditMode ? getDateValue(workOrderToView.deliveryDate) : getDateValue(orderDetails.deliveryDate)}
                                disabled={isViewMode && !isEditMode}
                                onChange={e => handleOrderDetailsChange('deliveryDate', new Date(e.target.value))} />
                              <Form.Control.Feedback type="invalid">
                                {errors[`deliveryDate`]}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </td>
                        </tr>

                        {/* Third Row */}
                        <tr>
                          <td style={{ fontWeight: 'bold' }}>Bill To</td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                as="textarea"
                                rows={4}
                                placeholder={
                                  "Organization Name\nOrganization Address Line 1\nOrganization Address Line 2\nOrganization Address Line 3"
                                }
                                value={isViewMode && !isEditMode ? workOrderToView.billTo : orderDetails.billTo}
                                disabled={isViewMode && !isEditMode}
                                isInvalid={errors[`billTo`]}
                                onChange={e => handleOrderDetailsChange('billTo', e.target.value)}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors[`billTo`]}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </td>
                          <td style={{ fontWeight: 'bold' }}>Ship To</td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                as="textarea"
                                rows={4}
                                placeholder={
                                  "Farm Name\nFarm Address Line 1\nFarm Address Line 2\nFarm Address Line 3"
                                }
                                value={isViewMode && !isEditMode ? workOrderToView.shipTo : orderDetails.shipTo}
                                disabled={isViewMode && !isEditMode}
                                isInvalid={errors[`shipTo`]}
                                onChange={e => handleOrderDetailsChange('shipTo', e.target.value)} />
                              <Form.Control.Feedback type="invalid">
                                {errors[`shipTo`]}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </td>
                        </tr>

                        {/* Fourth Row */}
                        <tr>
                          <td style={{ fontWeight: 'bold' }}>Att</td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                value={isViewMode && !isEditMode ? workOrderToView.attention1 : orderDetails.attention1}
                                disabled={isViewMode && !isEditMode}
                                isInvalid={errors[`attention1`]}
                                onChange={e => handleOrderDetailsChange('attention1', e.target.value)}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors[`attention1`]}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </td>
                          <td style={{ fontWeight: 'bold' }}>Att</td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                value={isViewMode && !isEditMode ? workOrderToView.attention2 : orderDetails.attention2}
                                disabled={isViewMode && !isEditMode}
                                isInvalid={errors[`attention2`]}
                                onChange={e => handleOrderDetailsChange('attention2', e.target.value)}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors[`attention2`]}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </td>
                        </tr>

                        {/* Fifth Row */}
                        <tr>
                          <td style={{ fontWeight: 'bold' }}>Tel</td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                value={isViewMode && !isEditMode ? workOrderToView.telephone1 : orderDetails.telephone1}
                                disabled={isViewMode && !isEditMode}
                                isInvalid={errors[`telephone1`]}
                                onChange={e => handleOrderDetailsChange('telephone1', e.target.value)}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors[`telephone1`]}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </td>
                          <td style={{ fontWeight: 'bold' }}>Tel</td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                value={isViewMode && !isEditMode ? workOrderToView.telephone2 : orderDetails.telephone2}
                                disabled={isViewMode && !isEditMode}
                                isInvalid={errors[`telephone2`]}
                                onChange={e => handleOrderDetailsChange('telephone2', e.target.value)}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors[`telephone2`]}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </td>
                        </tr>


                        {/* Sixth Row */}
                        <tr>
                          <td style={{ fontWeight: 'bold' }}>Email</td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type="email"
                                value={isViewMode && !isEditMode ? workOrderToView.email1 : orderDetails.email1}
                                disabled={isViewMode && !isEditMode}
                                isInvalid={errors[`email1`]}
                                onChange={e => handleOrderDetailsChange('email1', e.target.value)}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors[`email1`]}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </td>
                          <td style={{ fontWeight: 'bold' }}>Email</td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type="email"
                                value={isViewMode && !isEditMode ? workOrderToView.email2 : orderDetails.email2}
                                disabled={isViewMode && !isEditMode}
                                isInvalid={errors[`email2`]}
                                onChange={e => handleOrderDetailsChange('email2', e.target.value)}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors[`email2`]}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </td>
                        </tr>


                        {/* Seventh Row */}
                        <tr>
                          <td style={{ fontWeight: 'bold' }}>P.O #</td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                value={isViewMode && !isEditMode ? workOrderToView.poNumber : orderDetails.poNumber}
                                disabled={isViewMode && !isEditMode}
                                onChange={e => handleOrderDetailsChange('poNumber', e.target.value)}
                              />
                            </Form.Group>
                          </td>
                          <td style={{ fontWeight: 'bold' }}>Technician</td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                value={isViewMode && !isEditMode ? workOrderToView.technician : orderDetails.technician}
                                disabled={isViewMode && !isEditMode}
                                onChange={e => handleOrderDetailsChange('technician', e.target.value)}
                              />
                            </Form.Group>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ fontWeight: 'bold' }}>Billing Currency</td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                as='select'
                                required
                                value={isViewMode && !isEditMode
                                  ? workOrderToView.billingCurrency
                                  : orderDetails.billingCurrency
                                }
                                disabled={isViewMode && !isEditMode}
                                onChange={(e) => {
                                  handleOrderDetailsChange('billingCurrency', e.target.value);
                                }}
                              >
                                <option value=""></option>
                                <option value="CAD">CAD</option>
                                <option value="USD">USD</option>
                                <option value="EUR">EUR</option>
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                {errors[`billingCurrency`]}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </td>
                        </tr>

                      </tbody>
                    </Table>

                    <Table>
                      <thead>
                        <tr>
                          <th style={{ backgroundColor: '#005E7D', color: 'white' }}>Payment Terms & Notes</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <Form.Control
                              as="textarea"
                              rows={4}
                              value={workOrderToView && !isEditMode ? workOrderToView.paymentTerms : paymentTerms}
                              placeholder="Payment Terms"
                              required
                              disabled={isViewMode && !isEditMode}
                              onChange={(e) => { setPaymentTerms(e.target.value) }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors[`payment-terms`]}
                            </Form.Control.Feedback>
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                    <Table striped>
                      <thead>
                        <tr>
                          <th style={{ ...headerStyle, width: '7%' }}>QTY</th>
                          <th style={{ ...headerStyle, width: orders.some((order) => order.transfer) ? '22%' : '25%' }}>Description</th>
                          <th style={{ ...headerStyle, width: '25%' }}>Internal Notes</th>
                          <th style={{ ...headerStyle, width: '2%' }}>CTJ</th>
                          <th style={{ ...headerStyle, width: '2%' }}>Billable</th>
                          <th style={{ ...headerStyle, width: '5%' }}>Transfer</th>
                          {orders.some((order) => order.transfer) && <th style={{ ...headerStyle, width: '10%' }}>Transfer From</th>}
                          <th style={{ ...headerStyle, width: '2%' }}>Cost</th>
                          <th style={{ ...headerStyle, width: '7%' }}>Currency</th>
                          <th style={{ ...headerStyle, width: '10%' }}>Unit Price</th>
                          <th style={{ ...headerStyle, width: '10%' }}>Total Price</th>
                          {(!isViewMode || isEditMode) && <th style={{ ...headerStyle, width: '5%' }}>
                            <Button variant="outline-light" onClick={addOrder}>+</Button>
                          </th>}
                        </tr>
                      </thead>
                      <tbody>
                        {
                          (isViewMode && !isEditMode ? (workOrderToView.orders || []) : orders).map(
                            (order, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <Form.Control
                                      type="number"
                                      value={order.qty}
                                      rows={1}
                                      min={1}
                                      placeholder="QTY"
                                      isInvalid={errors[`qty-${index}`]}
                                      onChange={e => handleInputChange(index, 'qty', e.target.value)}
                                      disabled={isViewMode && !isEditMode}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors[`qty-${index}`]}
                                    </Form.Control.Feedback>
                                  </td>
                                  <td>
                                    <Form.Control
                                      as="textarea"
                                      rows={2}
                                      value={order.description}
                                      placeholder="Description"
                                      isInvalid={errors[`description-${index}`]}
                                      onChange={e => handleInputChange(index, 'description', e.target.value)}
                                      disabled={isViewMode && !isEditMode}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors[`description-${index}`]}
                                    </Form.Control.Feedback>
                                  </td>
                                  <td>
                                    <Form.Control
                                      as="textarea"
                                      rows={2}
                                      value={order.internalNotes}
                                      style={order.internalNotes == 'Indicate Shop or Site' ? { color: '#005E7D', fontWeight: 'bold' } : {}}
                                      placeholder="Internal Notes"
                                      isInvalid={errors[`internalNotes-${index}`]}
                                      onChange={e => handleInputChange(index, 'internalNotes', e.target.value)}
                                      disabled={isViewMode && !isEditMode}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors[`internalNotes-${index}`]}
                                    </Form.Control.Feedback>
                                  </td>
                                  <td>
                                    <Form.Check style={{ marginLeft: '35%' }} type="checkbox" checked={order.ctj} onChange={() => handleCTJChange(index)} disabled={isViewMode && !isEditMode} />
                                  </td>
                                  <td>
                                    <Form.Check style={{ marginLeft: '35%' }} type="checkbox" checked={order.billable} onChange={() => handleBillableChange(index)} disabled={isViewMode && !isEditMode} />
                                  </td>
                                  <td>
                                    <Form.Check style={{ marginLeft: '35%' }} type="checkbox" checked={order.transfer} onChange={() => handleTransferChange(index)} disabled={isViewMode && !isEditMode} />
                                  </td>
                                  {orders.some((order) => order.transfer) && <td>
                                    {(!isViewMode || isEditMode) ? <Form.Control
                                      as="select"
                                      value={orders[index]?.transferFrom}
                                      disabled={isViewMode && !isEditMode || !orders[index]?.transfer}
                                      onChange={(e) => handleTransferJobChange(index, e.target.value)}
                                    >
                                      <option key="jobNumberSelect"> </option>
                                      {jobNumbers.map((job) => (
                                        <option key={job._id} value={job.jobNumber}>
                                          {job.jobNumber}
                                        </option>
                                      ))}
                                    </Form.Control> :
                                      <Form.Control
                                        as="textarea"
                                        rows={1}
                                        value={order.transferFrom}
                                        disabled
                                      >

                                      </Form.Control>}
                                  </td>}
                                  <td>
                                    <Form.Check style={{ marginLeft: '35%' }} type="checkbox" checked={order.cost} onChange={() => handleCostCheckbox(index)} disabled={isViewMode && !isEditMode} />
                                  </td>
                                  <td>
                                    <Form.Control
                                      as='select'
                                      required
                                      value={order.purchaseCurrency}
                                      disabled={isViewMode && !isEditMode}
                                      onChange={e => handleInputChange(index, 'purchaseCurrency', e.target.value)}
                                    >
                                      <option value="CAD">CAD</option>
                                      <option value="USD">USD</option>
                                      <option value="EUR">EUR</option>
                                    </Form.Control>
                                  </td>
                                  <td>
                                    {
                                      order.cost ? <Form.Control
                                        type="text"
                                        value={order.unitPrice}
                                        placeholder="Cost + 15%"
                                        isInvalid={errors[`unitPrice-${index}`]}
                                        onChange={e => handleInputChange(index, 'unitPrice', e.target.value)}
                                        onBlur={(e) => {
                                          handleInputChange(index, 'unitPrice', e.target.value);
                                          handleInputChange(index, 'totalPrice', e.target.value);
                                        }}
                                      />
                                        : <Form.Control
                                          type="text"
                                          value={(isViewMode && !isEditMode)
                                            ? (order.ctj
                                              ? 'CTJ'
                                              : new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(order.unitPrice.replaceAll('$', '').replaceAll(',', '').trim()))
                                            : (order.ctj
                                              ? 'CTJ'
                                              : order.unitPrice)
                                          }
                                          placeholder="Unit Price"
                                          disabled={order.ctj || (isViewMode && !isEditMode)}
                                          isInvalid={errors[`unitPrice-${index}`]}
                                          onChange={e => handleInputChange(index, 'unitPrice', e.target.value)}
                                          onBlur={(e) => {
                                            const strippedValue = e.target.value.replace('$', '').replaceAll(',', '').trim();
                                            const parsed = parseFloat(strippedValue);
                                            const currencyValue = new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(
                                              parsed
                                            );

                                            if (isNaN(parsed)) {
                                              const err = {
                                                [`unitPrice-${index}`]: 'Unit Price is invalid.'
                                              };
                                              setErrors({ ...errors, ...err });
                                              return;
                                            }

                                            handleInputChange(index, 'unitPrice', currencyValue);
                                          }}
                                        />
                                    }
                                    <Form.Control.Feedback type="invalid">
                                      {errors[`unitPrice-${index}`]}
                                    </Form.Control.Feedback>
                                  </td>
                                  <td>
                                    {
                                      order.cost ?
                                        <Form.Control
                                          type="text"
                                          value={order.totalPrice}
                                          placeholder="Cost + 15%"
                                          disabled={true}
                                          isInvalid={errors[`totalPrice-${index}`]}
                                        />
                                        :
                                        <Form.Control
                                          type="text"
                                          value={order.ctj ? 'CTJ' : new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(
                                            parseFloat(order.totalPrice.replaceAll('$', '').replaceAll(',', '').trim() || 0),
                                          )}
                                          placeholder="Total Price"
                                          disabled={true}
                                          isInvalid={errors[`totalPrice-${index}`]}
                                          onChange={e => handleInputChange(index, 'totalPrice', e.target.value.replace('$', '').trim())}
                                        />}
                                    <Form.Control.Feedback type="invalid">
                                      {errors[`totalPrice-${index}`]}
                                    </Form.Control.Feedback>
                                  </td>

                                  {(!isViewMode || isEditMode) &&
                                    <td>
                                      <Button variant="outline-danger" onClick={() => removeOrder(index)}>-</Button>
                                    </td>
                                  }
                                </tr>
                              )
                            })
                        }
                      </tbody>
                      <tfoot style={{ backgroundColor: '#005E7D', color: 'white' }}>
                        <tr>
                          <th>Total</th>
                          {Array(orders.some((order) => order.transfer) ? 9 : 8).fill(null).map((_, index) => <th key={index}></th>)}
                          <th>{new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(
                            parseFloat(calculateTotal() || 0),
                          )}</th>
                          <th></th>
                        </tr>
                      </tfoot>
                    </Table>


                    {(isEditMode || isViewMode) && <Table>
                      <thead>
                        <tr>
                          <th style={{ backgroundColor: '#005E7D', color: 'white' }}>Internal Revision Comments</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <Form.Control
                              as="textarea"
                              rows={4}
                              value={workOrderToView && !isEditMode ? workOrderToView.revisionComments : revisionComments}
                              disabled={isViewMode && !isEditMode}
                              onChange={(e) => { setRevisionComments(e.target.value) }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>}

                    {(!isViewMode && !isEditMode) &&
                      <Row>
                        <Col style={{ textAlign: 'center' }}>
                          <Button className='w-100' variant="outline-dark" type="submit">Submit Work Order</Button>
                        </Col>
                      </Row>
                    }
                    {isEditMode &&
                      <Row>
                        <Col style={{ textAlign: 'center', width: '100%' }}>
                          <Button className='w-100' variant="outline-success" type="submit">Update Work Order</Button>
                        </Col>
                      </Row>
                    }
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
};

export default WorkOrderForm;